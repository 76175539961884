import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import FadeInWhenVisible from '../components/fadeInWhenVisible'
import CustomLink from '../components/CustomLink.js'
import Cta from '../components/cta'
import ColumnAlternatingYouTube from '../components/columnAlternatingYouTube'
import CardIcons from '../components/cardIcons.js'
import Card from '../components/card'
import Showdown from 'showdown'

const ServicesTemplate = ({ data }) => {
  const post = data.markdownRemark
  const converter = new Showdown.Converter()  

  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}           
          type="root"
        />
      </div>

      {post.frontmatter.section.length > 0 && (
        <section className="bg-white">
          <FadeInWhenVisible>
            <MDBContainer>
              <div className="about-overview">
                {post.frontmatter.section.map((sections, index) => (
                  <ColumnAlternatingYouTube
                    key={index}
                    headingLevel={2}
                    title={sections.title}
                    subtitle={sections.subtitle}
                    openingpara={sections.openingpara}
                    description={sections.description}
                    vidUrl={sections.videourl}
                    placement={sections.placement}
                    titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium"
                    link={sections.link}
                    linktext={sections.linktext}
                  />
                ))}
              </div>
            </MDBContainer>
          </FadeInWhenVisible>
        </section>
      )}

      {post.frontmatter.feature && (
        <section className="bg-white-grey-gradient">
          <FadeInWhenVisible>
            <MDBContainer>
              <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium pb-5">
                {post.frontmatter.feature.title}
              </h2>
              {post.frontmatter.feature.featurecard && (
                <MDBRow>
                  {post.frontmatter.feature.featurecard.map((cards, index) => (
                    <CardIcons
                      key={index}
                      collg="4"
                      colmd="6"
                      headingLevel={0}
                      headingClass="font-alt font-w-700 letter-spacing-1 title-small title-xs-medium pt-3 card-heading"
                      title={cards.title}
                      subtitle={cards.subtitle}
                      image={cards.image.childImageSharp.gatsbyImageData}
                      alt={cards.alttext}
                      anchorlink={cards.anchorlink}
                      anchortext={cards.anchortext}
                    />
                  ))}
                </MDBRow>
              )}
            </MDBContainer>
          </FadeInWhenVisible>
        </section>
      )}

      {post.frontmatter.insight && (
        <>
        {post.frontmatter.insight.map((insights, index) => (
          <section className="bg-light-blue-prods" key={index}>
            <FadeInWhenVisible>
              <MDBContainer>
                <MDBRow>
                  <MDBCol>
                    <span id={insights.anchorlink} className="anchor"> &nbsp; </span> 
                    <div
                      className="mt-3 richtext richtext-image divlink"
                      dangerouslySetInnerHTML={{ __html: converter.makeHtml(insights.description), }}
                    />                  
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
            </FadeInWhenVisible>
          </section>
        ))}
        </>
      )}

      {post.frontmatter.compliance != '' && post.frontmatter.compliance != null && (
        <>
          {post.frontmatter.compliance.map((compliances, index) => (
            <FadeInWhenVisible key={index}>
              <section className="bg-white">
                <MDBContainer>
                  <MDBRow>
                    <MDBCol md="12" className="pr-5 mb-5">
                      <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                        {compliances.title}
                      </h2>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    {compliances.compfeature.map((compfeatures, index) => (
                      <MDBCol lg="4" md="6" className="pb-5 item-prods d-flex" key={index} >
                        <div className="card border-0 flex-fill">
                          <div className="card-body">
                            <div style={{ minWidth: '66px', maxWidth: '66px', }} >
                              <GatsbyImage
                                image={ compfeatures.image.childImageSharp.gatsbyImageData }
                                className="mb-3"
                                alt={compfeatures.alttext}
                              />
                            </div>
                            <p className="text-medium text-blue-dark font-w-700">
                              {compfeatures.title}
                            </p>
                            <p className="text-medium text-gray-dark">
                              {compfeatures.description}
                            </p>
                          </div>
                          <div className="card-footer border-top-0">
                            <span className="text-medium-small pl-3">
                              <CustomLink link={compfeatures.link} className="effect">
                                {compfeatures.linktext}
                              </CustomLink>
                            </span>
                          </div>
                        </div>
                      </MDBCol>
                    ))}
                  </MDBRow>
                </MDBContainer>
              </section>
            </FadeInWhenVisible>
          ))}
        </>
      )}

      {post.frontmatter.helpfulresources && (
        <section className="bg-white-grey-gradient">
          <FadeInWhenVisible>
            <MDBContainer>
            <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                {post.frontmatter.helpfulresources.title}
              </h2>
              {post.frontmatter.helpfulresources.helpfulres && (
                <MDBRow className="pt-5">
                  {post.frontmatter.helpfulresources.helpfulres.map((helpfulres, index) => (
                    <Card
                      key={index}
                      collg={(post.frontmatter.helpfulresources.helpfulres.length % 3 === 0) ? "4" : (post.frontmatter.helpfulresources.helpfulres.length % 4 === 0) ? "3" : "4" }
                      colmd="6"
                      height="8.5rem"
                      title={helpfulres.title}
                      subtitle={helpfulres.subtitle}
                      description={helpfulres.description}
                      image={helpfulres.image.childImageSharp.gatsbyImageData}
                      alt={helpfulres.alttext}
                      placement={helpfulres.placement}
                      link={ helpfulres.link ? helpfulres.link : helpfulres.document.publicURL }
                      titleclass="title-small"
                      descriptionClass="text-card-small"
                    />
                  ))}
                </MDBRow>
              )}
            </MDBContainer>
          </FadeInWhenVisible>
        </section>
      )}

      <Cta
        ctatitle={post.frontmatter.cta.title}
        ctadescription={post.frontmatter.cta.description}
        ctalink={post.frontmatter.cta.link}
        ctalinktext={post.frontmatter.cta.linktext}
      />
    </Layout>
  )
}
export default ServicesTemplate

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "services" } }
    ) {
      frontmatter {
        meta {
          title
          description
        }
        title
        subtitle
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        alttext
        ctatext
        ctaslug
        cta {
          title
          description
          linktext
          link
        }
        section {
          title
          subtitle
          openingpara
          description
          videourl
          placement
          link
          linktext
        }
        feature {
          title
          btntext
          btnlink
          document {
            publicURL
          }
          featurecard {
            title
            subtitle
            alttext
            anchorlink
            anchortext
            image {
              childImageSharp {
                gatsbyImageData(width:70, height: 70, quality: 90) 
              }
            }
          }
        }
        insight {
            anchorlink
            description
        }
        compliance {
          title
          compfeature {
            title
            description
            link
            linktext
            alttext
            image {
              childImageSharp {
                gatsbyImageData(width: 46, height: 46, quality: 90) 
              }
            }
          }
        }
        helpfulresources {
          title
          helpfulres {
            title
            subtitle
            description
            placement
            link
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90) 
              }
            }
            document {
              publicURL
            }
          }
        }
      }
      html
    }
  }
`